import { REDIRECT_URI } from './protectedRoute'
import { AuthConfiguration } from './authConfiguration'
import {
    AuthAdapter,
    AuthInfo,
    identityProviderTypes,
    RedirectPayload,
    SigninResult,
    SignupOptions,
    TokenExchangeType,
    TokenType,
    updateStore
} from './shared'
import {
    captureException,
    authSetAccessToken,
    authSetIdp,
    authSetType,
    store,
    saveState,
    authIsLoggedIn,
    resetAuthState,
    captureMessage
} from '@thriveglobal/thrive-web-core'
import jwtDecode from 'jwt-decode'
import { AuthType } from '../../store/selectors/auth'
import { Avo, clearTracking } from '@thriveglobal/thrive-web-tracking'

interface azureAuthOptions {
    azureToken?: string
    azureRelayPath: string
}

const authAdapters = {
    azuread: async () => {
        const { createAzureAdapter } = await import('./adapters/azureAdapter')
        return createAzureAdapter()
    },
    identity: async () => {
        const { createIdentityAdapter } = await import(
            './adapters/identity/identityAdapter'
        )
        return createIdentityAdapter()
    },
    identity_internal: async () => {
        const { createIdentityInternalAdapter } = await import(
            './adapters/identity/IdentityInternalAdapter'
        )
        return createIdentityInternalAdapter()
    },
    identity_sso: async () => {
        const { createIdentitySsoAdapter } = await import(
            './adapters/identity/identitySso'
        )
        return createIdentitySsoAdapter()
    },
    calls: async () => {
        const { createCallsAdapter } = await import(
            './adapters/identity/callsAdapter'
        )
        return createCallsAdapter()
    },
    magiclink: async () => {
        const { createMagicLinkAdapter } = await import(
            './adapters/identity/magicLinkAdapter'
        )
        return createMagicLinkAdapter()
    },
    unknown: async () => {
        const error = new Error('Unknown Auth Adapter Exception')
        captureException(error, { message: 'Unknown auth adapter was called' })
        throw error
    }
}

export const SUPPORTED_AUTH_TYPES_SET = new Set(Object.keys(authAdapters))

export function inferAuthType(accessToken: string): AuthType {
    if (!accessToken) {
        return null
    }
    try {
        const parsedJwtToken = jwtDecode(accessToken) as any
        if (parsedJwtToken?.iss?.includes('realms/ThriveGlobal')) {
            return AuthType.identity
        } else if (parsedJwtToken?.iss?.includes('realms/master')) {
            return AuthType.identity_internal
        }
        return null
    } catch (error) {
        return null
    }
}

// Auth class used to be configured in its constructor and an instance was exported, making it impossible
// to configure it before the constructor fired. For now, make Auth creation lazy and make note to refactor this
// for v2 of the sign-in flow.

let authInstance: AuthAdapter
const getAuthInstance = async (): Promise<AuthAdapter | null> => {
    const authStore = store.getState().auth
    let authType = authStore.authType as unknown as AuthType
    if (!authType) {
        const accessToken = authStore.accessToken
        const inferredAuthType = inferAuthType(accessToken)
        authType = inferredAuthType
    }
    const authTypeExists = authType !== null
    const authTypeHasChanged = authInstance?.type !== authType
    const authInstanceDoesNotExist = !authInstance
    if (!authTypeExists || !SUPPORTED_AUTH_TYPES_SET.has(authType)) {
        return null
    }
    if (authInstanceDoesNotExist || authTypeHasChanged) {
        authInstance = await authAdapters[authType]()
    }
    return authInstance
}

const configureAuth = (authConfiguration: AuthInfo) => {
    updateStore(authConfiguration)
}

const configureAzure = async (azureAuthOptions: azureAuthOptions) => {
    // Migrating away from overwriting the access token
    if (azureAuthOptions.azureToken) {
        captureMessage('configureAzure is overriding the access token')
        store.dispatch(authSetAccessToken(azureAuthOptions.azureToken))
    }

    store.dispatch(authSetIdp('azuread'))
    saveState(
        REDIRECT_URI,
        `${window.location.origin}${azureAuthOptions.azureRelayPath}`
    )
    captureMessage('azure adapter configured', {
        category: 'auth'
    })
}

const redirect = async (payload: RedirectPayload) =>
    (await getAuthInstance())?.redirect(payload)

const configure = async (authConfiguration: AuthConfiguration) =>
    (await getAuthInstance())?.configure(authConfiguration)

const sendResetPasswordEmail = async (email: string) =>
    (await getAuthInstance())?.sendResetPasswordEmail(email)

const resetPassword = async (email: string, code: string, password: string) =>
    (await getAuthInstance())?.resetPassword(email, code, password)

const setAuthType = (identityProviderType: identityProviderTypes) => {
    // Cast to any as a temporary fix while we depend on core
    const identityType = identityProviderType.toLowerCase() as any
    store.dispatch(authSetType(identityType))
}

const getAuthType = () => store.getState().auth.authType

const signUp = async (signupOptions: SignupOptions) => {
    return (await getAuthInstance())?.signUp(signupOptions)
}

export async function _SignOut() {
    try {
        const authInstance = await getAuthInstance()
        if (authInstance !== null) {
            await authInstance.signOut()
        }
        Avo.signoutCompleted({
            activityType: 'user_signout',
            featureType: 'identity',
            userId_: null,
            isSystemEvent: null
        })

        //clear segment local storage and cookies
        clearTracking()
        if (!authInstance) {
            store.dispatch(authIsLoggedIn(false))
            store.dispatch(resetAuthState())
            localStorage.clear()
            window.location.href = `${window.location.origin}/logout`
        }
    } catch (error) {
        Avo.signoutCompleted({
            activityType: 'user_signout',
            featureType: 'identity',
            userId_: null,
            isSystemEvent: null
        })
        captureException(
            error as Error,
            { message: 'MFE Sign Out Failed' },
            'sign-in'
        )
    }
}

// signIn and signOut are called as side effects in a reducer,
// so run them in the next tick in the event where the auth instance
// has to be initialized and use the store (which isn't allowed during
// reducer execution)
const signOut = () => {
    setTimeout(_SignOut)
}

const signIn = (email?: string, password?: string): Promise<SigninResult> => {
    return new Promise((resolve, reject) => {
        setTimeout(async () => {
            ;(await getAuthInstance())
                .signIn(email, password)
                .then(resolve)
                .catch(reject)
        })
    })
}

const refreshToken = async () => {
    return (await getAuthInstance())?.refreshToken()
}

const tokenExchange = async (
    token: string,
    tokenType: TokenType,
    referrerId?: string,
    userAcceptedTnC?: boolean
): Promise<SigninResult | void> => {
    const authInstance = await getAuthInstance()
    if (tokenType === 'azure') {
        return authInstance?.tokenExchange(
            token,
            'azure',
            referrerId,
            userAcceptedTnC
        ) as Promise<SigninResult>
    } else {
        await authInstance?.tokenExchange(
            token,
            'saml2',
            referrerId,
            userAcceptedTnC
        )
    }
    return
}

const isServerSessionStillActive = async (): Promise<boolean> => {
    const authInstance = await getAuthInstance()
    return authInstance?.isServerSessionStillActive()
}

export default {
    redirect,
    signOut,
    signIn,
    configureAuth,
    configureAzure,
    setAuthType,
    getAuthType,
    configure,
    signUp,
    getAuthInstance,
    sendResetPasswordEmail,
    resetPassword,
    refreshToken,
    tokenExchange,
    isServerSessionStillActive
}
